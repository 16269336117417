import TenantContext, { TenantContextType } from "../context/tenant";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import StyledBounceLoader from "../components/StyledBounceLoader";

const BASE_URL = import.meta.env.VITE_API_DOMAIN;

const TenantGlobalStyles = createGlobalStyle`
  :root {
    --theme-color: ${(props) => props.theme.color};
    --theme-light: ${(props) => props.theme.light};
    //TODO are these used?
    --theme-dark: ${(props) => props.theme.dark};;
    --theme-forest: #1b652e;
  }
`;

type TenantConfiguration = {
  config: TenantContextType;
  theme: any;
};

const TenantProvider = ({ children }: any): JSX.Element => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [tenant, setTenant] = useState<TenantConfiguration | null>(null);
  const tenantInfo = useMemo(() => tenant, [tenant]);

  useEffect(() => {
    const loadTenantData = async (): Promise<void> => {
      const currentTenant: TenantConfiguration =
        import.meta.env.VITE_TENANT_ID === "1"
          ? await import("../machines/mna.json")
        : import.meta.env.VITE_TENANT_ID === "2" 
          ? await import("../machines/hbff.json") 
        : import.meta.env.VITE_TENANT_ID === "3" 
          ? await import("../machines/gre.json")
        // default to mna for now, replace with generic eventually
        : await import("../machines/mna.json");

        currentTenant.config["learning"] = await getLearningData();
        setTenant(currentTenant);
    };

    const getLearningData = async (): Promise<any> => {
      let learningData = localStorage.getItem('learningData') ? JSON.parse(localStorage.getItem('learningData') || "{}") : null;   
      let learningDataUpdatedAt = localStorage.getItem('learningDataUpdatedAt');   

      if (!isAuthenticated) {
        return learningData;
      }

      // refresh learningData if more than hoursExpire old
      let hoursOld = Math.abs(new Date(learningDataUpdatedAt || "").valueOf() - new Date().valueOf()) / 3600000;
      let hoursExpire = .083;  // temp 5 minute cache for testing
      
      if (!learningData || !learningDataUpdatedAt || hoursOld > hoursExpire) {
        // load learning data from API if not in local storage
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(BASE_URL + "/api/v2/learning", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "X-Tenant-Id": tenant?.config.id || "1",
          },
        });

        if (response.status != 200) {
          return {};
        }

        learningData = await response.json();

        localStorage.setItem('learningData', JSON.stringify(learningData));
        localStorage.setItem('learningDataUpdatedAt', (new Date()).toISOString());
      }

      return learningData;
    };

    loadTenantData();
  });

  return tenantInfo ? (
    <TenantContext.Provider value={tenantInfo.config}>
      <ThemeProvider theme={tenantInfo.theme}>
        <TenantGlobalStyles />
        {children}
      </ThemeProvider>
    </TenantContext.Provider>
  ) : (
    <div className="mx-auto my-16 max-w-5xl">
      <div className="flex items-center justify-center">
        <StyledBounceLoader />
      </div>
    </div>
  );
};

export default TenantProvider;
